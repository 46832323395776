import React from 'react'
import photo from '../images/aboutus.png'
import './Mani.css'
import NumberTicker from './NumberTicker'; // Make sure to create and import this component

function AboutUs() {
    return (
        <section id="WhyShopyAds" className="w-full sectionServices grid h-full py-5 xl:py-14 relative bg-white">
            {/* Overlay */}
            {/* <div class="absolute inset-0 bg-black z-10 opacity-60"></div> */}
            {/* Background Image */}
            {/* <div class="absolute inset-0 bg-cover bg-center" style="background-image: url('./images/stars.avif');"></div> */}
            {/* Content */}
            <div className="relative z-10 flex justify-center items-center pb-10 w-full h-full">
                <div className="flex xl:flex-row flex-col justify-center xl:gap-12 items-center w-full px-2 sm:px-4 md:px-8 lg:px-16 xl:px-24 2xl:px-64">
                    <div className="flex justify-center items-center w-full xl:w-1/2">
                        <img src={photo} className='xl:w-full w-2/3 ' alt />
                    </div>
                    <div className="flex px-3 flex-col mt-10 xl:mt-0  justify-center items-center xl:items-start gap-8 text-neutral-800 w-full xl:w-1/2">
                        <h1 className="font-[800] capitalize   xl:leading-[84px] leading-[54px] text-[43px]  xl:text-[73px] text-neutral-800">
                            About Us</h1>

                        <p className="font-[400] xl:text-start text-center capitalize leading-[25px] text-[17px] text-neutral-800">

                            We are a multi-service agency specializing in e-commerce and marketing, leveraging extensive experience to drive business growth and deliver outstanding value and results. </p>
                        <p className="font-[400] xl:text-start text-center capitalize leading-[25px] text-[17px] text-neutral-800">

                            Our expertise empowers businesses, firms, entrepreneurs, and enterprises to thrive in the digital landscape through tailored solutions designed for maximum impact.
                        </p>

                        <div className="flex flex-col gap-3 justify-center w-full items-center xl:items-start">
                            <a href="#form" className=" text-neutral-800 hover:scale-105 rounded-full border-b-[4px] hover:border-b-[1px] scroll-link uppercase font-[700] leading-[22px] hover:bg-white hover:text-gray-900  text-[17px] flex gap-2 items-center justify-center w-fit border border-neutral-800 py-3 px-10 transition-all duration-300">
                                Join Us
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>

                            </a>

                            <div className="flex xl:justify-start justify-center   items-center ">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 border-gray-200 text-white">
                                    <defs>
                                        <linearGradient id="half-yellow" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="50%" style={{ stopColor: "#ffde59", stopOpacity: 1 }} />
                                            <stop offset="50%" style={{ stopColor: "currentColor", stopOpacity: 1 }} />
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#half-yellow)" fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                </svg>

                                <p className="font-[500] capitalize leading-[15px] text-[15px] text-neutral-800 ml-2"> Based
                                    on 3500+
                                    Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className='xl:flex hidden sectionNumbers xl:flex-row flex-col xl:justify-between mt-10 items-center bg-gray-50 gap-5 xl:w-4/5 xl:px-24  px-3 w-full mx-auto rounded-xl pt-11 pb-20   '>
                <div className='flex flex-col justify-center items-center gap-5'>
                    <h1 className="font-[600] text-center capitalize leading-[44px]  xl:leading-[64px] text-[18px] xl:text-[28px] text-neutral-800">
                        Team
                    </h1>
                    <h4 className="font-[800] uppercase leading-[16px] xl:text-[46px] text-[38px] text-neutral-800">
                        +<NumberTicker start={0} end={25} duration={2000} />

                    </h4>
                </div>

                <div className='flex flex-col justify-center items-center gap-5'>
                    <h1 className="font-[600] text-center capitalize leading-[44px]  xl:leading-[64px] text-[18px] xl:text-[28px] text-neutral-800">
                        Years experience
                    </h1>
                    <h4 className="font-[800] uppercase leading-[16px] xl:text-[46px] text-[38px] text-neutral-800">
                        +<NumberTicker start={0} end={5} duration={2000} />


                    </h4>
                </div>

                <div className='flex flex-col justify-center items-center gap-5'>
                    <h1 className="font-[600] text-center capitalize leading-[44px]  xl:leading-[64px] text-[18px] xl:text-[28px] text-neutral-800">
                        Happy clients
                    </h1>
                    <h4 className="font-[800] uppercase leading-[16px] xl:text-[46px] text-[38px] text-neutral-800">
                        +<NumberTicker start={0} end={6500} duration={2000} />

                    </h4>
                </div>

                <div className='flex flex-col justify-center items-center gap-5'>
                    <h1 className="font-[600] text-center capitalize leading-[44px]  xl:leading-[64px] text-[18px] xl:text-[28px] text-neutral-800">
                        Amount Spent
                    </h1>
                    <h4 className="font-[800] uppercase leading-[16px] xl:text-[46px] text-[38px] text-neutral-800">
                        +<NumberTicker start={0} end={200} duration={2000} />M$

                    </h4>
                </div>
            </div>
            <div className=' xl:hidden grid xl:grid-cols-4 grid-cols-1 xl:w-2/3 w-full mx-auto sectionNumbers py-14   gap-14'>
                <div className='flex flex-col xl:justify-center justify-start items-center gap-5 '>
                    <h1 className="font-[600] w-full text-center capitalize leading-[24px]  xl:leading-[64px] text-[18px] xl:text-[28px] text-neutral-800">
                        Team
                    </h1>
                    <h4 className="font-[800] uppercase leading-[16px] xl:text-[46px] text-[38px] text-neutral-800">
                        +<NumberTicker start={0} end={25} duration={2000} />

                    </h4>
                </div>

                <div className='flex justify-between items-center px-5'>
                    <div className='flex flex-col justify-center  items-center gap-5'>
                        <h1 className="font-[600] text-center capitalize leading-[24px]  xl:leading-[64px] text-[18px] xl:text-[28px] text-neutral-800">
                            Amount Spent
                        </h1>
                        <h4 className="font-[800] uppercase leading-[16px] xl:text-[46px] text-[38px] text-neutral-800">
                            +<NumberTicker start={0} end={200} duration={2000} />M$

                        </h4>
                    </div>

                    <div className='flex flex-col justify-center  items-center gap-5'>
                        <h1 className="font-[600] text-center w-full capitalize leading-[24px]  xl:leading-[64px] text-[18px] xl:text-[28px] text-neutral-800">
                            Happy clients
                        </h1>
                        <h4 className="font-[800] uppercase leading-[16px] xl:text-[46px] text-[38px] text-neutral-800">
                            +<NumberTicker start={0} end={6500} duration={2000} />

                        </h4>
                    </div>
                </div>
                <div className='flex flex-col xl:justify-center justify-start items-center gap-5 '>
                    <h1 className="font-[600] w-full text-center capitalize leading-[24px]  xl:leading-[64px] text-[18px] xl:text-[28px] text-neutral-800">
                        Years experience
                    </h1>
                    <h4 className="font-[800] uppercase leading-[16px] xl:text-[46px] text-[38px] text-neutral-800">
                        +<NumberTicker start={0} end={5} duration={2000} />

                    </h4>
                </div>



            </div>


        </section>
    )
}

export default AboutUs