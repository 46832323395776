import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import photo1 from '../images/page.png'
import photo2 from '../images/briefcase.png'
import photo3 from '../images/profile.png'
import photo4 from '../images/No-Ban.png'
import photoAnti from '../images/optimiseImage/4.png'
import photoVip from '../images/optimiseImage/2.png'

import Form from '../components/Form'
import Faq from '../components/Faq'

function AdvancedStructure() {



    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);



    return (
        <div className='grid  place-items-center w-full sectionServices'>


            <section className="w-full sectionServices  h-fit  xl:pb-10 pb-8 relative bg-gray-50">
                <div className="relative z-10 xl:py-14 xl:mt-12  py-4 w-full h-full">

                    <div className="flex   w-full h-full justify-center xl:flex-row flex-col xl:-mt-[40px] mt-[30px]  px-2 items-center xl:px-24  px-3 px-3 ">

                        <div className="flex flex-col w-full   text-center  h-full justify-center  pb-14 xl:pb-0 xl:gap-0     items-center">
                            <h4 className="font-[800] capitalize xl:w-3/5 leading-[44px] xl:leading-[64px] text-[34px] xl:text-[48px] text-neutral-800">

                                Secure and Scale Your Ads with Our Exclusive Bulletproof Solutions</h4>

                            <div className="flex  justify-center  w-full py-10 items-center ">
                                <img src={photo4} className='w-full xl:w-3/5 ' alt='image' />
                            </div>

                            <p className="font-[400] leading-[36px] xl:w-4/5  text-[17px] xl:text-[18px] text-neutral-800"> Maximize security, boost performance, and scale your campaigns with confidence. With enhanced protection and optimized strategies, ensure success and a seamless, secure experience.</p>

                            <a href="#MetaAssets" className="text-neutral-800 rounded-full border-b-[4px] hover:border-b-[1px] mt-10 hover:scale-105 scroll-link uppercase font-[700] leading-[22px] bg-white hover:text-neutral-800  text-[15px] xl:text-[17px] flex gap-2 items-center justify-center w-fit border border-neutral-800 py-3 xl:px-10 px-10 transition-all duration-300">
                                <span>read more</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>


                <div className='flex  sectionTilte flex-col  py-14 items-center bg-gray-50 gap-5  xl:w-4/5 w-full mx-auto rounded-xl   xl:px-24 '>


                    <h1 className="font-[800] capitalize   xl:leading-[54px] leading-[34px] text-[30px]  xl:text-[53px] text-neutral-900">
                        We Provide You With
                    </h1>
                    <p className="font-[500]  text-center capitalize leading-[25px] text-[20px] text-neutral-800">
                        Everything you need to ensure the continuity of your advertising campaigns and avoid random bans:
                    </p>

                </div>



            </section>


            <div id='MetaAssets' className="relative z-10 flex justify-center items-center  w-full py-12 xl:py-24 bg-black h-full">
                <div className="flex xl:flex-row flex-col justify-center xl:gap-12 items-center w-full ">

                    <div className="flex flex-col mt-10 xl:mt-0  justify-center items-center   text-neutral-100 w-full">
                        <h1 className="font-[800] capitalize   xl:leading-[84px] leading-[54px] text-[30px]  xl:text-[53px] text-neutral-100">
                            Meta Assets  </h1>
                        <p className="font-[400]  text-center capitalize leading-[25px] text-[17px] text-neutral-200">
                            Unlock Premium Digital Resources to Amplify Your Marketing Success
                        </p>

                        <div className="flex justify-center mt-14 items-center gap-3  w-full h-full px-2 sm:px-4 md:px-6 lg:px-10 xl:px-16 2xl:px-24  px-3 ">
                            <div className="grid gap-6 xl:grid-cols-3 w-full ">
                                <div className="flex w-full justify-start flex-col gap-6 items-center rounded-xl bg-white/20 py-8 px-4">
                                    <div className="flex justify-center  items-center p-2 ">
                                        <img src={photo3} className='h-[70px]  ' />
                                    </div>
                                    <div className="grid text-center gap-5 ">
                                        <h1 className="font-[800] capitalize leading-[30px] text-[19px] xl:text-[22px] text-gray-100">Aged Reinstated Facebook Profiles</h1>
                                        <p className="font-[300] capitalize leading-[25px] text-[16px] text-gray-100">Reinstated multiple times and aged for stability, these profiles come with up to weeks of warranty, ensuring secure and reliable ad campaigns.</p>
                                    </div>
                                </div>
                                <div className="flex w-full justify-start flex-col gap-6 items-center rounded-xl bg-white/20 py-8 px-4">
                                    <div className="flex justify-center items-center p-2 ">
                                        <img src={photo2} className='h-[70px]' />
                                    </div>
                                    <div className="grid text-center gap-5">
                                        <h1 className="font-[800] capitalize leading-[30px] text-[19px] xl:text-[22px] text-gray-100">Aged Reinstated Business Managers</h1>
                                        <p className="font-[300] capitalize leading-[25px] text-[16px] text-gray-100">Reinstated Twice for reliability, these verified BMs come with up to a week of warranty, ensuring secure management of your agency's ad accounts.</p>
                                    </div>
                                </div>
                                <div className="flex w-full justify-start flex-col gap-6 items-center rounded-xl bg-white/20 py-8 px-4">
                                    <div className="flex justify-center items-center p-2 ">
                                        <img src={photo1} className='h-[70px]' />
                                    </div>
                                    <div className="grid text-center gap-5">
                                        <h1 className="font-[800] capitalize leading-[30px] text-[19px] xl:text-[22px] text-gray-100">Aged Reinstated Facebook Pages</h1>
                                        <p className="font-[300] capitalize leading-[25px] text-[16px] text-gray-100">Reinstated multiple times, these pages come with likes and followers and can be renamed to match your brand, making them the perfect choice to build trust.</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


            <section className="w-full grid sectionServices xl:gap-24 gap-10  h-fit py-12 px-2 sm:px-4 md:px-6 lg:px-10 xl:px-16 2xl:px-24 relative bg-gray-50">

                <div className="relative z-10 grid gap-14 w-full h-full">
                    <div className='flex justify-center items-center gap-3 flex-col'>
                        <h1 className="font-[800] capitalize text-center   xl:leading-[84px] leading-[44px] text-[30px]  xl:text-[53px] text-neutral-900">
                            Anit-Ban Structure Setup </h1>
                        <p className="font-[400]  text-center capitalize leading-[25px] text-[19px] text-neutral-900">
                            Maximize Your Campaigns—No Worries About Bans with Our Golden Bulletproof Advanced Structure
                        </p>
                    </div>

                    <div className="flex xl:gap-10 gap-5  w-full h-full justify-center xl:flex-row flex-col  items-center  px-3 ">

                        <div className="flex  justify-center xl:w-1/2  w-full xl:py-10 items-center ">
                            <img src={photoAnti} className=' xl:h-[28rem] md:h-[20rem]' alt='image' />
                        </div>



                        <div className="flex flex-col xl:w-1/2  h-full items-center justify-center xl:p-10 p-5   border border-2 hover:border-black  rounded-2xl border-black transition-all duration-200">
                            <h4 className="font-[800] text-center capitalize leading-[28px] xl:leading-[44px] text-[20px] xl:text-[26px] text-neutral-800"> Why Is It Necessary to Have Our Golden Bulletproof Advanced Structure Setup?</h4>


                            <ol className='flex justify-center items-start flex-col gap-3 list-disc pl-5 py-10'>
                                <li><span className='font-[700]'>Uninterrupted Ads:</span> Ads continue running, even if more assets get banned-no loss of valuable data or pixels.</li>
                                <li><span className='font-[700]'>Unique and Long-Lasting:</span> Built to last a lifetime—no  replacements needed.</li>
                                <li><span className='font-[700]'>Scale Beyond 7 Figures:</span> Scale efficiently and securely without the worry of bans.</li>
                                <li><span className='font-[700]'>Expert Support:</span> No actions needed from you; our experts handle any issues. Focus on driving sales and growing your business.</li>
                            </ol>


                            <a href="https://api.whatsapp.com/send/?phone=%2B447456443839&text&type=phone_number&app_absent=0" target='_blank' className="text-neutral-800 rounded-full border-b-[4px] hover:border-b-[1px] hover:scale-105 mt-3 scroll-link uppercase font-[700] leading-[22px] bg-white hover:text-neutral-800  text-[15px] xl:text-[17px] flex gap-2 items-center justify-center w-full mx-auto border border-neutral-800 py-3 xl:px-10 px-2 transition-all duration-300">

                                Get Yours Now
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" viewBox="0 0 308 308" xmlSpace="preserve">
                                    <g id="XMLID_468_">
                                        <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156   c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687   c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887   c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153   c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348   c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802   c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922   c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0   c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458   C233.168,179.508,230.845,178.393,227.904,176.981z" />
                                        <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716   c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396   c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z    M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188   l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677   c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867   C276.546,215.678,222.799,268.994,156.734,268.994z" />
                                    </g>
                                </svg>
                            </a>
                        </div>

                    </div>

                </div>


                <div className="relative z-10 grid gap-14 w-full h-full">
                    <div className='flex justify-center items-center gap-3 flex-col'>
                        <h1 className="font-[800] capitalize text-center   xl:leading-[84px] leading-[44px] text-[30px]  xl:text-[53px] text-neutral-900">
                            Elite VIP Insights & Solutions </h1>
                        <p className="font-[400]  text-center capitalize leading-[25px] text-[19px] text-neutral-900">
                            Our VIP BI Team  will provide you with insights & solutions to grow your business
                        </p>
                    </div>

                    <div className="flex xl:gap-10 gap-5  w-full h-full justify-center xl:flex-row flex-col   items-center  ">
                        <div className="flex xl:hidden justify-center md:w-1/2  w-full md:py-10 py-5 items-center ">
                            <img src={photoVip} className='w-full  ' alt='image' />
                        </div>

                        <div className="flex flex-col xl:w-1/2  h-full items-center justify-center xl:p-10 p-5   border border-2 hover:border-black  rounded-2xl border-black transition-all duration-200">
                            <h4 className="font-[800]  text-center capitalize leading-[28px] xl:leading-[44px] text-[20px] xl:text-[26px] text-neutral-800"> Meta VIP Support Solutions and Benefits</h4>



                            <ol className='flex justify-center items-start flex-col gap-3 list-disc pl-5 py-10'>
                                <li><span className='font-[700]'>Financial Management:</span> We manage invoicing and credit lines while resolving any payment issues related to Meta invoices.

                                </li>
                                <li><span className='font-[700]'>License Registration:</span> We register licenses for various categories, including gambling, adult products, and dating, ensuring compliance.
                                </li>
                                <li><span className='font-[700]'>Profile & Account Management:</span> We handle unbanning of profiles, Business Managers, and Pages, while fixing ad account limitations and re-approving rejected creatives.

                                </li>
                                <li><span className='font-[700]'>Competitor Insights:</span> We provide detailed targeting data and insights on your competitors' ads to help you gain a competitive edg</li>
                            </ol>


                            <a href="https://api.whatsapp.com/send/?phone=%2B447456443839&text&type=phone_number&app_absent=0" target='_blank' className="text-neutral-800 rounded-full border-b-[4px] hover:border-b-[1px] hover:scale-105  mt-3 scroll-link uppercase font-[700] leading-[22px] bg-white hover:text-neutral-800  text-[15px] xl:text-[17px] flex gap-2 items-center justify-center w-full border border-neutral-800 py-3 xl:px-10 px-2 transition-all duration-300">
                                <span>become a part of our vip clients</span>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" viewBox="0 0 308 308" xmlSpace="preserve">
                                    <g id="XMLID_468_">
                                        <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156   c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687   c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887   c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153   c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348   c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802   c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922   c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0   c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458   C233.168,179.508,230.845,178.393,227.904,176.981z" />
                                        <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716   c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396   c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z    M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188   l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677   c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867   C276.546,215.678,222.799,268.994,156.734,268.994z" />
                                    </g>
                                </svg>
                            </a>
                        </div>


                        <div className="xl:flex hidden justify-center xl:w-1/2  w-full py-10 items-center ">
                            <img src={photoVip} className=' xl:h-[28rem] ' alt='image' />
                        </div>




                    </div>

                </div>



                <div className='flex justify-center w-full items-center gap-3 flex-col xl:w-2/3 mx-auto'>
                    <h1 className="font-[800] capitalize text-center   xl:leading-[84px] leading-[44px] text-[30px]  xl:text-[53px] text-neutral-900">
                        Unlock Your Business Potential Today
                    </h1>
                    <p className="font-[400]  text-center capitalize leading-[25px] xl:text-[19px] text-[17px] text-neutral-900">
                        Our full suite of services is designed to empower your business, streamline your operations, and ensure sustained growth. From secure ad management to VIP insights, we provide everything you need for long-term success.

                    </p>

                    <a href="#form" className="text-neutral-800 rounded-full border-b-[4px] hover:border-b-[1px] text-center hover:scale-105  mt-3 scroll-link capitalize font-[700] leading-[22px] bg-white hover:text-neutral-800  text-[15px] xl:text-[17px] flex gap-2 items-center justify-center w-fit border border-neutral-800 xl:py-5 py-2 mx-2 xl:px-10 px-2 transition-all duration-300">
                        <span>
                            Don’t Wait—Fill Out the Form Below to Get Started!</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>

                    </a>
                </div>

            </section>


            <Form />
            <Faq />
        </div>
    )
}

export default AdvancedStructure