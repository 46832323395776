import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

function CookiePolicy() {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div className='flex flex-col sectionServices w-full xl:px-40 px-3 py-20 gap-5 '>  <h1 className='text-[50px] text-center mb-12 font-[700]'>Cookie Policy</h1>
            <p>This Cookie Policy is part of our Privacy Policy. For more information about us and how we protect visitor information, please see our <a href="/privacy-policy">Privacy Policy</a>.</p>
            <b>What are Cookies and Other Tracking Technologies?</b>
            <p>A Cookie is a small text file that can be stored on and accessed from your device when you visit one of our Sites (“Cookie”). Other tracking technologies work similarly to Cookies and place small data files on your devices or monitor your website activity to enable us to collect information about how you use our Sites. This allows our Sites to recognize your device from those of other users. You can find more information at <a href="https://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a> and <a href="https://www.youronlinechoices.eu" target="_blank" rel="noopener noreferrer">www.youronlinechoices.eu</a>.</p>
            <b>How do our Sites Use Cookies and Other Tracking Technologies?</b>
            <p>ShopyAds uses Cookies to identify you and your interests, to remember your preferences, and to track your use of our Sites. We also use Cookies to control access to certain content on our Sites, protect the Sites, better interact with you, and to process any requests that you make of us. To administer our Sites and for research purposes, ShopyAds also has contracted with third-party service providers to track and analyze statistical usage and volume information from our Site users. These third-party service providers use persistent Cookies to help us improve the user experience, manage our Site content, and analyze how users navigate and utilize the Sites. The Cookies on the Site can be classified in three different manners described below:</p>
            <h3>Provenance: Who is putting the Cookies on your device</h3>
            <ul>
                <li><b>First-Party Cookies:</b> Cookies that belong to ShopyAds and that ShopyAds places on your device.</li>
                <li><b>Third-Party Cookies:</b> Cookies that another party places on your device through our Site. ShopyAds may contract with third-party service providers to send e-mails to users who have provided us with their contact information. These third-party service providers may place Cookies on the devices of these users to measure and improve the effectiveness of our e-mail communications.</li>
            </ul>
            <h3>Duration of Cookies</h3>
            <ul>
                <li><b>Persistent Cookies:</b> Used to improve your experience of using the Sites, such as recording your acceptance of our Cookie Policy.</li>
                <li><b>Session Cookies:</b> Temporary and deleted from your machine when your web browser closes. Used to track internet usage as described above. You may refuse to accept browser Cookies by activating the appropriate setting on your browser. However, if you select this setting, you may be unable to access certain parts of the Sites. The data collected will not be kept for longer than one year.</li>
            </ul>
            <h3>Purpose of Cookies</h3>
            <ul>
                <li><b>Strictly Necessary:</b> Let you move around the Site and use essential features. For security purposes and to ensure proper functioning.</li>
                <li><b>Performance:</b> Collect information about how you use our Site, such as which pages you visit and any errors. Helps us improve the Site and measure the effectiveness of our advertising.</li>
                <li><b>Functionality:</b> Remember settings you’ve applied such as layout, text size, and language preferences. Provide and show embedded video content and enable our chat function.</li>
                <li><b>Marketing:</b> Track your online activity to deliver more relevant advertising or to limit how many times you see an ad. These Cookies can share information with third parties.</li>
            </ul>
            <b>How do I Refuse or Manage My Consent to the Use of Cookies?</b>
            <p>We respect your right to privacy. If you do not want Cookies to be dropped on your device or wish to change your existing Cookie settings, you can adjust your settings by clicking the link below. If you block all Cookies, it may impact your experience, and some Site functionality may be disabled.</p>
            <p><a href="/manage-cookies">Manage Cookies</a></p>
            <p>Strictly Necessary Cookies are automatically set on your browser when you visit our Site and cannot be disabled using our cookie banner. For more information on the development of user profiles and the use of targeting/advertising Cookies, please see <a href="https://www.youronlinechoices.eu" target="_blank" rel="noopener noreferrer">www.youronlinechoices.eu</a> if you are located in Europe or <a href="https://www.aboutads.info/choices" target="_blank" rel="noopener noreferrer">www.aboutads.info/choices</a> if in the United States.</p>
            <b>Contact Us</b>
            <p>For any questions about this Cookie Policy, please contact us at:</p>
            <p>ShopyAds<br />
                312 W 2ND ST STE 3625<br />
                CASPER, WY 82601<br />
                Email: <a href="mailto:accounts@ShopyAds-agency.com">accounts@ShopyAds-agency.com</a></p>
        </div>
    )
}

export default CookiePolicy