import React from 'react'
import photo from '../images/To_logo_light.png'
import photo2 from '../images/MKD-.png'
import photo3 from '../images/logo_light.png'
import photo4 from '../images/DropShip-China-Pro.png'
import photo5 from '../images/tripel.png'
import photo6 from '../images/cropped-logo-SAAD-BEN-WEB-RVB-2.png'
import './Mani.css'
function Companies() {
    return (
        <div className="w-full flex flex-col sectionServiceswhite justify-center items-center gap-4 py-5 relative ">
            {/* <h1 className="font-[400] capitalize leading-[24px] text-[24px] text-gray-100">Partner with ShopyAds</h1> */}
            <div className="scroll my-4">
                <div className="scroll-content  ">
                    <div>
                        <span><img src={photo} alt="Logo 1" className='h-10' /></span>
                        <span><img src={photo2} alt="Logo 1" className='h-10' /></span>
                        <span><img src={photo4} alt="Logo 2" className='h-10' /></span>
                        <span><img src={photo5} alt="Logo 3" className='h-10' /></span>
                        <span><img src={photo3} alt="Logo 4" className='h-10' /></span>
                        <span><img src={photo6} alt="Logo 5" className='h-10' /></span>

                    </div>
                    <div>
                        <span><img src={photo} alt="Logo 1" className='h-10' /></span>
                        <span><img src={photo2} alt="Logo 1" className='h-10' /></span>
                        <span><img src={photo4} alt="Logo 2" className='h-10' /></span>
                        <span><img src={photo5} alt="Logo 3" className='h-10' /></span>
                        <span><img src={photo3} alt="Logo 4" className='h-10' /></span>
                        <span><img src={photo6} alt="Logo 5" className='h-10' /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Companies